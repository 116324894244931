<template>
	<main>
		<div class="main">
			<div class="content">
				<div class="title">{{ title }}</div>
				<div class="form">
					<el-form ref="loginForm" :model="form" :rules="rules" @keydown.enter.native="login">
						<el-form-item label="账号" prop="username">
							<el-input v-model="form.username"/>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input type="password" v-model="form.password"/>
						</el-form-item>
					</el-form>
				</div>
				<div class="submit">
					<el-button type="primary" block class="el-block" @click="login" :loading="submit_loading">登录</el-button>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import {auth} from "@/api/admin";
import {site_config} from "@/config";
import "@/plugins/admin"

export default {
	data() {
		return {
			submit_loading: false,
			rules: {
				username: [
					{required: true, message: "请输入账号"}
				],
				password: [
					{required: true, message: "请输入密码"}
				]
			},
			form: {}
		}
	},
	computed: {
		title() {
			return `${site_config.title}`
		}
	},
	methods: {
		login() {
			if (!this.checkForm()) {
				return false;
			}
			this.submit_loading = true;
			auth.login(this.form).then((res) => {
				this.$store.commit('admin/setToken', res.token)
				this.$router.push({
					path: '/admin/'
				})
			}).finally(() => {
				this.submit_loading = false;
			})
		},
		checkForm() {
			var valida_all = true;
			this.$refs['loginForm'].validate((valid) => {
				if (!valid) {
					valida_all = false;
				}
			})
			return valida_all;
		}
	},
}
</script>

<style lang="scss" scoped>
.el-block {width: 100%;}
.main {position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: #efefef;display: flex;align-items: center;justify-content: center;
	.content {padding: 30px;background: #fff;width: 500px;
		.title {text-align: center;font-size: 18px;font-weight: bold;}
		.form {margin-top: 15px;}
	}
}
</style>

